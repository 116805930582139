<template>
  <v-row no-gutters justify="center">
    <v-col cols="12" md="10">
      <v-alert v-if="error" text dense type="error">
        {{ error }}
      </v-alert>

      <v-row v-else>
        <v-col cols="12" md="4" v-for="(item, i) in items" :key="`item-${i}`">
          <v-card flat>
            <v-card-title v-text="item.sectionTitle" class="text-subtitle-2 px-0" />

            <v-card-text class="pa-0">
              <v-hover v-slot:default="{ hover }" >
                <v-card outlined :hover="hover" :to="item.link.to" :disabled="item.disabled">
                  <v-card-text>
                    <v-row>
                      <!-- Avatar -->
                      <v-col cols="12" class="text-center pt-0">
                        <v-avatar v-if="item.avatar" max-height="15rem" max-width="100%" width="auto" tile size="164">
                          <v-img v-if="item.avatar.image" :src="item.avatar.image" />

                          <v-icon v-else-if="item.avatar.icon" v-text="item.avatar.icon" size="164" />

                          <span v-else>{{ item.avatar }}</span>
                        </v-avatar>
                      </v-col>

                      <!-- Title -->
                      <v-col cols="12" class="text-center text-subtitle-1 font-weight-bold" v-text="item.title" />

                      <!-- Text -->
                      <v-col cols="12" class="text-center" v-text="item.text" />
                    </v-row>
                  </v-card-text>

                  <v-card-actions v-if="item.link" class="justify-center">
                    <v-btn text small color="primary darken-2">
                      {{ item.link.text }}
                      <v-icon v-text="mdiChevronRight" />
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-hover>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiChevronRight,
  mdiFolderAccountOutline,
  mdiDatabaseSettings,
  mdiFormatColorFill
} from '@mdi/js'

export default {
  name: 'pibot-settings-main',
  data: () => ({
    error: false,
    mdiChevronRight
  }),
  computed: {
    items () {
      const items = [
        {
          sectionTitle: 'Account',
          avatar: {
            icon: mdiFolderAccountOutline
          },
          title: 'My Profile',
          text: 'Manage your profile',
          link: {
            to: '/settings/profile',
            text: 'Continue'
          }
        },
        {
          sectionTitle: 'Data',
          avatar: {
            icon: mdiDatabaseSettings
          },
          title: 'Data Manager',
          text: 'Manage data available in PIBOT',
          link: {
            to: this.$route.path,
            text: 'Coming soon'
          },
          disabled: true
        },
        {
          sectionTitle: 'GIS',
          avatar: {
            icon: 'map'
          },
          title: 'GIS Viewer settings',
          text: 'Manage GIS Viewer settings',
          link: {
            to: this.$route.path,
            text: 'Coming soon'
          },
          disabled: true
        },
        {
          sectionTitle: 'Appearance',
          avatar: {
            icon: mdiFormatColorFill
          },
          title: 'PIBOT Customization',
          text: 'Manage the appearance of PIBOT',
          link: {
            to: this.$route.path,
            text: 'Coming soon'
          },
          disabled: true
        }
      ]

      if (this.$grantAccess(this.$me, 'updateAny', 'profile')) {
        const item = {
          sectionTitle: 'Admin',
          avatar: {
            icon: 'supervisor_account'
          },
          title: 'Administrator',
          text: 'List and manage the profiles of other PIBOT users',
          link: {
            to: '/settings/users',
            text: 'Continue'
          }
        }
        items.splice(1, 0, item)
      }

      return items
    }
  }
}
</script>
